


























import {
  Component,
  PropSync,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import _ from 'lodash';

import FirmItemBus from '@/bus/FirmItemBus';
import Employee from '@modules/declaration/entities/Employee';

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class SelectEmployeeModal extends Vue {
  @PropSync('show', { type: Boolean, required: true })
   visible!: Boolean;

  @State(state => state.UserModule.currentFirmId)
  readonly firmId!: number;

  @firmUpdateModule.Action('employeeSearch')
  readonly employeeSearch!: (payload: { query: string, firmId: number }) => Promise<Employee[]>;

  @firmUpdateModule.Action('employeeFetch')
  readonly employeeFetch!: (payload: { uuid: string, firmId: number }) => Promise<Employee>;

  selectedEmployeeUuid: string | null = null;
  options: Employee[] = [];
  loading: boolean = false;

  get filteredEmployees(): Employee[] {
    return _.orderBy(this.options, ['label'], ['asc']);
  }

  @Watch('selectedEmployeeUuid')
  async selectItem(value: string | null) {
    if (value !== null) {
      const employee = await this.employeeFetch({ uuid: value, firmId: this.firmId });

      FirmItemBus.$emit('selectItem', employee);
      this.close();
    }
  }

  async searchEmployee(query: string) {
    if (!query.length) {
      this.options = [];
      return;
    }

    this.loading = true;
    this.options = await this.employeeSearch({ query, firmId: this.firmId });
    this.loading = false;
  }

  close() {
    this.$emit('update:show', false);
  }

  valid() {
    this.$emit('valid');
    this.close();
  }
}
